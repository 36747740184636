* {
  margin: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  display: none;
}
html {
  font-size: 62.5%;
  height: 100%;
}
body,
#root {
  height: 100%;
}
body {
  background-color: #f5f5f5;
}
#root {
  background-color: #f5f5f5;
}
#chart .apexcharts-datalabels {
  display: none;
}
.splide__slide {
  display: flex;
  justify-content: center;
}
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap"); /* .wrapper {
  padding: 0 4rem;
  max-width: 1536px;
  margin: 0 auto;
} */
.react-pdf__Page__annotations.annotationLayer {
  height: inherit !important;
}