.custom-table :where(td, th) {
    background-color: #FFF !important;
    font-weight: 500 !important;
    font-size: 1.4rem;
    color: #616161 !important
}

.custom-table td {
    font-weight: 400 !important;
}
.custom-table table tr th{
    width: 350px

}